import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';

export const options = {
  // renderMark: {
  //   [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  // },
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => <h1 className="title is-3">{children}</h1>,
    [BLOCKS.HEADING_2]: (node, children) => (
      <h2 className="title is-4 has-text-black has-text-weight-bold">{children}</h2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => <h3 className="title is-5">{children}</h3>,
    [BLOCKS.HEADING_4]: (node, children) => <h4 className="title is-6">{children}</h4>,

    [BLOCKS.EMBEDDED_ASSET]: node => {
      const { title, description, file } = node.data.target.fields;
      const mimeType = file['en-US'].contentType;
      const mimeGroup = mimeType.split('/')[0];

      switch (mimeGroup) {
        case 'image':
          return (
            <figure className="image">
              <img
                title={title ? title['en-US'] : null}
                alt={description ? description['en-US'] : null}
                src={file['en-US'].url}
              />
            </figure>
          );
        case 'application':
          return (
            <a alt={description ? description['en-US'] : null} href={file['en-US'].url}>
              {title ? title['en-US'] : file['en-US'].details.fileName}
            </a>
          );
        default:
          return (
            <span style={{ backgroundColor: 'red', color: 'white' }}>
              {' '}
              {mimeType} embedded asset{' '}
            </span>
          );
      }
    },
  },
};

export default options;
