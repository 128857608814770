import React from 'react';
import { graphql } from 'gatsby';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/hero';

import {options } from '../config/contentful';

export default function EndorsementTemplate({
  data: {
    post: { title, body, excerpt, bio, photo },
  }, // this prop will be injected by the GraphQL query below.
}) {
  const infoBox = (
    <article
      className="media"
      style={
        {
          // marginTop: 48,
          // marginBottom: 48,
          // paddingTop: 48,
          // borderTop: '2px solid #efefef',
        }
      }
    >
      <figure className="media-left">
        <p className="image " style={{ maxWidth: 256 }}>
          <img
            className="is-rounded"
            style={{ border: '4px solid #fff' }}
            alt={`${title} picture`}
            src={photo && photo.fluid.src}
          />
        </p>
      </figure>
      <div className="media-content">
        <div className="content" style={{ fontSize: `1rem` }}>
          <p className=" is-size-5 heading">{title}</p>
          {bio && bio.bio}
        </div>
      </div>
    </article>
  );

  return (
    <Layout container={false}>
      <SEO
        title={`${title} Endorses Vanna Howard`}
        description={excerpt ? excerpt.excerpt : ''}
        image={photo.fluid.src}
      />
      <Hero title={`${title} Endorses Vanna Howard`} color="light" centered container />
      <main className="section">
        <div className="container is-narrow">
          <div className="content">{body && documentToReactComponents(body.json, options)}</div>
        </div>
        <br />
        <div className="container">
          <div className="notification is-info box">{infoBox}</div>
        </div>
      </main>
    </Layout>
  );
}
export const pageQuery = graphql`
  query($slug: String!) {
    post: contentfulEndorsement(slug: { eq: $slug }) {
      title
      excerpt {
        excerpt
      }
      bio {
        bio
      }
      photo {
        fluid(maxWidth: 600) {
          sizes
          src
          srcSet
        }
      }
      body {
        json
      }
    }
  }
`;
