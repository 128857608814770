import React from 'react';
import PropTypes from 'prop-types';

function Hero({
  children,
  title,
  backgroundImage,
  subtitle,
  container,
  color,
  size,
  centered,
  bold,
}) {
  const heroClass = `hero is-${color} is-${size} ${centered ? 'has-text-centered' : ''} ${
    bold ? 'is-bold' : ''
  }`;
  const heroStyle = backgroundImage
    ? { backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover' }
    : null;
  return (
    <section className={heroClass} style={heroStyle}>
      <div className="hero-body">
        <div className={container ? 'container' : 'container is-fullwidth'}>
          <h1 className="title is-1">{title}</h1>
          {subtitle && <h2 className="subtitle is-5 heading has-text-muted">{subtitle}</h2>}
          {children}
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  container: PropTypes.bool,
  color: PropTypes.oneOf([
    'primary',
    'light',
    'info',
    'link',
    'warning',
    'danger',
    'success',
    'dark',
  ]),
  size: PropTypes.oneOf(['', 'medium', 'large', 'fullheight']),
  bold: PropTypes.bool,
  centered: PropTypes.bool,
};

Hero.defaultProps = {
  children: null,
  title: '',
  subtitle: null,
  container: false,
  color: '',
  size: '',
  bold: false,
  centered: false,
};

export default Hero;
